import { createMuiTheme } from '@material-ui/core/styles';
import { createTheme, createColor } from '@manakin/theme';
import { white, yellow, mountain, black } from '../colors';
import amber from '@material-ui/core/colors/amber';
import blue from '@material-ui/core/colors/blue';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import loginBackgroundImage from '../../assets/login-background.jpg';
import accountBackgroundImage from '../../assets/account-background.jpg';
import logo from '../../assets/logo.svg';
import pdfIcon from '../../assets/pdf.png';
import loginVisual from '../../assets/login-visual.svg';
import footerLogo from '../../assets/footer-logo.svg';
import Cookies from 'js-cookie';

const green = createColor('#3e9618');
const brown = createColor('#796E65');

const _primary = Cookies.get('primary') || '#E46609';
const _secondary = Cookies.get('secondary') || '#C43E47';
const _tertiary = Cookies.get('tertiary') || '#3C4248';
const _palette = Cookies.get('palette') || '#3C4248';
const _tertiaryPaletteColor = createColor('#69B6CA');

const theme = (obj = {}) => {
	const primaryButtonBackgroundColor = obj.primaryButtonBackgroundColor;
	const primaryButtonHoverBackgroundColor = obj.primaryButtonHoverBackgroundColor;
	const primaryButtonBorderColor = obj.primaryButtonBorderColor;
	const primaryButtonHoverBorderColor = obj.primaryButtonHoverBorderColor;
	const secondaryButtonBackgroundColor = obj.secondaryButtonBackgroundColor;
	const secondaryButtonHoverBackgroundColor = obj.secondaryButtonHoverBackgroundColor;
	const secondaryButtonBorderColor = obj.secondaryButtonBorderColor;
	const secondaryButtonHoverBorderColor = obj.secondaryButtonHoverBorderColor;
	const primaryFontColor = obj.primaryFontColor;
	const homePageBlockPrimaryBackgroundColor = obj.homePageBlockPrimaryBackgroundColor;
	const homePageBlockSecondaryBackgroundColor = obj.homePageBlockSecondaryBackgroundColor;

	let _obj = {};
	_obj.primaryButtonBackgroundColor = obj.primaryButtonBackgroundColor;
	_obj.primaryBackgroundColor = obj.primaryBackgroundColor;
	_obj._primary = _primary;
	_obj._secondary = obj.themeColor || _secondary;
	_obj._tertiary = _tertiary;
	_obj._palette = _palette;
	_obj.indication = obj.indicationColor || green;
	_obj._primaryWorkform = _primary;
	_obj._secondaryWorkform = _secondary;
	_obj.buttonRadius = obj.buttonBorderRadius || '0px';
	_obj.homepageButtonRadius = obj.homepageButtonRadius || '0px';
	_obj.homeLinkColor = white;

	//dynamic colors
	_obj.defaultBox = obj.defaultBoxColor || _palette;
	_obj.defaultElement = obj.defaultWorkformColor || obj.defaultElementColor || _obj._secondary;
	_obj.primaryElement = obj.primaryElementColor || obj.primaryWorkformColor || _obj._secondary;
	_obj.secondaryElement = obj.defaultWorkformColor || obj.secondaryElementColor || _obj._primary;
	_obj.defaultWorkform = obj.defaultWorkformColor || _obj._secondary;
	_obj.primaryWorkform = obj.primaryWorkformColor || obj.primaryElementColor || _obj._primary;
	_obj.secondaryWorkform = obj.secondaryWorkformColor || obj.secondaryElementColor || _obj._secondaryWorkform;

	//Dashboard
	_obj.dashboardContentColor = createColor(_palette);
	_obj.dashboardTitleColor = createColor(primaryFontColor || _palette);
	_obj.dashboardResumeButton = createColor(obj.themeColor || _primary);
	_obj.dashboardBackgroundColor = createColor(_obj._secondary);

	///////COLORS
	_obj.createdPalette = createColor(_obj._palette);
	_obj.indicationColor = createColor(_obj.indication);

	//ELEMENT COLORS
	_obj.defaultElementColor = createColor(_obj.defaultElement);
	_obj.primaryElementColor = createColor(_obj.primaryElement);
	_obj.secondaryElementColor = createColor(_obj.secondaryElement);

	//WORKFORM COLORS
	_obj.defaultWorkformColor = createColor(_obj.defaultWorkform);
	_obj.primaryWorkformColor = createColor(_obj.primaryWorkform);
	_obj.secondaryWorkformColor = createColor(_obj.secondaryWorkform);

	//primary and secondary colors
	_obj.primaryColor = createColor(_obj._primary);
	_obj.secondaryColor = createColor(_obj._secondary);
	_obj.tertiaryColor = createColor(_obj._tertiary);
	_obj.mountainColor = createColor(mountain);

	//Palette colors (Used for buttons, icons, navigation enz)
	_obj.primaryPaletteColor = _obj.createdPalette;
	_obj.secondaryPaletteColor = white;
    _obj.tertiaryPaletteColor = _tertiaryPaletteColor;

	//type colors
	_obj.typeColor = _obj._tertiary;
	_obj.primaryTypeColor = white;
	_obj.secondaryTypeColor = _obj.createdPalette;

	//box backgroundColors
	_obj.defaultBoxBGColor = _obj.createdPalette;
	_obj.boxBGColor = createColor(_obj.primaryBackgroundColor);

	//box name colors
	_obj.defaultBoxColor = createColor(_obj.defaultBox);
	_obj.primaryBoxColor = white;
	_obj.secondaryBoxColor = _obj.createdPalette;

	//element colors
	_obj.defaultElementBGColor = _obj.defaultElementColor;
	_obj.primaryElementBGColor = _obj.primaryElementColor;
	_obj.secondaryElementBGColor = _obj.secondaryElementColor;

	//backgroundColors
	_obj.defaultBackgroundColor = _obj.primaryColor;
	_obj.primaryFontColor = createColor(primaryFontColor || _obj._palette);
	_obj.defaultTitleColor = createColor(_obj._palette);
	_obj.secondaryBackgroundColor = createColor(_obj._secondary);
	_obj.defaultContentColor = createColor('#3C4248');

	//Menu colors
	_obj.mainMenuBackground = _obj.primaryPaletteColor;
	_obj.mainMenuColor = white;
	_obj.mainMenuSecondaryColor = white;

	//navigation colors
	_obj.navigationColor = _obj.primaryPaletteColor;
	_obj.secondaryNavigationColor = _obj.primaryPaletteColor;
	_obj.linkColor = _obj.primaryPaletteColor;
	_obj.defaultContentHover = _obj.secondaryColor;
	_obj.LessonButtonColor = createColor(obj.themeColor || _obj._palette);
	_obj.defaultBorderHoverColor = _obj.secondaryColor;

	//BUTTONS
	_obj.homeButtonBGColor = createColor(primaryButtonBackgroundColor || obj.themeColor || _obj._palette);
	_obj.homeButtonBGColorHover = createColor(primaryButtonHoverBackgroundColor || _obj.homeButtonBGColor[700]);
	_obj.homeButtonBorderColor = createColor(primaryButtonBorderColor || _obj.homeButtonBGColor[700]);
	_obj.homeButtonBorderColorHover = createColor(primaryButtonHoverBorderColor || _obj.homeButtonBGColorHover[700]);

	_obj.buttonBGColor = createColor(obj.themeColor || _obj._palette);
	_obj.buttonBGColorHover = createColor(_obj.buttonBGColor[700]);
	_obj.buttonBorderColor = createColor(_obj.buttonBGColor[700]);
	_obj.buttonBorderColorHover = createColor(_obj.buttonBGColorHover[700]);

	//SECONDARY
	_obj.homeSecondarybuttonBGColor = createColor(secondaryButtonBackgroundColor || '#FFF');
	_obj.homeSecondarybuttonBGColorHover = createColor(secondaryButtonHoverBackgroundColor || _obj.homeSecondarybuttonBGColor[700]);
	_obj.homeSecondarybuttonBorderColor = createColor(secondaryButtonBorderColor || _obj.primaryPaletteColor[500]);
	_obj.homeSecondarybuttonBorderColorHover = createColor(secondaryButtonHoverBorderColor || _obj.primaryPaletteColor[700]);

	//rest
	_obj.correctAnswer = _obj.indicationColor;
	_obj.defaultBorderColor = createColor('#E6E6E6');
	_obj.secondaryBorderColor = createColor('#D9DBE5');
	_obj.loginBackground = createColor('#F5F5F5');
	_obj.percentageDone = createColor('#58942F');

    _obj.softBorderColor = createColor('#EFEFEF');
    _obj.subHeaderColor = createColor('#8A8A8A');
	_obj.homePageBlockPrimaryBackgroundColor = createColor(homePageBlockPrimaryBackgroundColor) || obj.primaryWorkform;
	_obj.homePageBlockSecondaryBackgroundColor = createColor(homePageBlockSecondaryBackgroundColor) || obj.secondaryWorkform;

	_obj.contentWidthXL = '1600px';
	_obj.contentWidthL = '1200px';
	_obj.contentWidthM = '1050px';
	_obj.contentWidthS = '700px';
	_obj.contentWidthXS = '340px';
	//breakpoints
	_obj.smartphoneBreakpoint = '@media only screen and (min-width : 375px)';
	_obj.tabletPortraitBreakpoint = '@media only screen and (min-width : 768px)';
	_obj.tabletLandscapeBreakpoint = '@media only screen and (min-width : 1024px)';
	_obj.laptopBreakpoint = '@media only screen and (min-width : 1280px)';
	_obj.desktopBreakpoint = '@media only screen and (min-width : 1400px)';
	_obj.desktopXLBreakpoint = '@media only screen and (min-width : 2100px)';

	//margins and paddings
	_obj.mainMenuListItemPaddingTop = '25px';
	_obj.mainMenuListItemPaddingBottom = '25px';
	_obj.defaultPadding = '25px';

	//fonts
	_obj.defaultTitleFont = 'Netto OT';
	_obj.secondaryTitleFont = 'Netto OT';
	_obj.workformQuestionFont = 'Netto OT';
	_obj.defaultAnswerFont = "Istok Web";
	_obj.defaultContentFont = "'Roboto', sans-serif";

	_obj.workformTitleFont = {
		fontFamily: _obj.defaultContentFont,
		fontSize: '4rem',
		lineHeight: '130%',
		letterSpacing: 0,
		fontWeight: 800,
	};

	//heights
	_obj.defaultMobileMenuHeight = '64px';
	_obj.defaultDesktopMenuHeight = '100px';

	_obj.defaultWrapper = {
		maxWidth: _obj.contentWidthM,
		position: 'relative',
		margin: '0 auto',
		width: '100%',
		paddingLeft: '25px',
		paddingRight: '25px',
	};
	_obj.extraSmallWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthXS,
	};
	_obj.smallWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthS,
	};
	_obj.largeWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthL,
	};
	_obj.extraLargeWrapper = {
		..._obj.defaultWrapper,
		maxWidth: _obj.contentWidthXL,
	};

	_obj.footerLogoStyle = {
		width: '10rem',
		height: '3rem',
		backgroundSize: 'contain',
		backgroundRepeat: 'no-repeat',
		position: 'absolute',
		right: '10rem',
		bottom: '3rem',
	};

	_obj.registrationHeading = {
		large: {
			textAlign: 'center',
			width: '100%',
			maxWidth: '600px',
			fontSize: '40px',
			lineHeight: '130%',
			margin: '0 auto 1em',
		},
		small: {
			fontSize: '28px',
			letterSpacing: '.2rem',
		},
	};

	_obj.listStyle = {
		'& ol, & ul': {
			padding: 0,
			counterReset: 'li-index',
			listStyle: 'none',
		},
		'& li': {
			position: 'relative',
			paddingLeft: '2.75rem',
		},
		'& li::before': {
			content: "''",
			position: 'absolute',
			top: 0,
			left: 0,
		},
		'& ul li::before': {
			top: '12px',
			width: '8px',
			height: '8px',
			background: 'currentColor',
			borderRadius: '50%',
		},
		'& ol li::before': {
			counterIncrement: 'li-index',
			content: 'counter(li-index)',
		},
	};

	_obj.breakpoints = createBreakpoints({});

	const realTheme = _theme(_obj);

	return realTheme;
};

const _theme = (props) =>
	createMuiTheme({
		typography: {
			htmlFontsize: 10,
			fontFamily: [
				props.defaultContentFont,
				'-apple-system',
				'BlinkMacSystemFont',
				'"Segoe UI"',
				'Roboto',
				'"Helvetica Neue"',
				'Arial',
				'sans-serif',
				'"Apple Color Emoji"',
				'"Segoe UI Emoji"',
				'"Segoe UI Symbol"',
			].join(','),
		},
		palette: {
			primary: props.primaryPaletteColor,
			secondary: props.secondaryPaletteColor,
			success: {
				light: green[200],
				main: green[400],
				dark: green[600],
				contrastText: '#ffffff',
			},
			info: {
				light: blue[300],
				main: blue[400],
				dark: blue[500],
				contrastText: '#ffffff',
			},
			warning: {
				light: amber[400],
				main: amber[600],
				dark: amber[700],
				contrastText: '#ffffff',
			},
		},
		manakin: createTheme({
			accountBackgroundImage: accountBackgroundImage,
			correctAnswer: props.correctAnswer,
			defaultActiveColor: green,
			defaultAnswerFont: props.defaultAnswerFont,
			defaultBackgroundColor: props.defaultBackgroundColor,
			defaultBorderColor: props.defaultBorderColor,
			defaultBorderHoverColor: props.defaultBorderHoverColor,
			defaultBoxBGColor: props.defaultBoxBGColor,
			defaultBoxColor: props.defaultBoxColor,
			defaultButtonBorderColor: props.buttonBorderColor,
			defaultButtonRadius: props.buttonRadius,
			defaultContentColor: props.defaultContentColor,
			defaultContentFont: props.defaultContentFont,
			defaultContentHover: props.defaultContentHover,
			defaultDesktopMenuHeight: props.defaultDesktopMenuHeight,
			defaultElementBGColor: props.defaultElementBGColor,
			defaultMobileMenuHeight: props.defaultMobileMenuHeight,
			defaultPadding: props.defaultPadding,
			defaultTitleColor: props.defaultTitleColor,
			defaultTitleFont: props.defaultTitleFont,
			defaultWorkformColor: props.defaultWorkformColor,
			defaultWrapper: props.defaultWrapper,
			desktopBreakpoint: props.desktopBreakpoint,
			desktopXLBreakpoint: props.desktopXLBreakpoint,
			extraLargeWrapper: props.extraLargeWrapper,
			extraSmallWrapper: props.extraSmallWrapper,
			footerLogo: footerLogo || logo,
			homeLinkColor: props.homeLinkColor,
			homePageBlockPrimaryBackgroundColor: props.homePageBlockPrimaryBackgroundColor,
			homePageBlockSecondaryBackgroundColor: props.homePageBlockSecondaryBackgroundColor,
			indicationColor: props.indicationColor,
			laptopBreakpoint: props.laptopBreakpoint,
			largeWrapper: props.largeWrapper,
			layoutButtonStyle: {
				borderColor: 'transparent',
				color: brown[500],
				backgroundColor: white[500],
			},
			layoutButtonStyleSelected: {
				backgroundColor: props.primaryPaletteColor[500],
				border: 0,
				color: white[500],
			},
			LessonButtonColor: props.LessonButtonColor,
			linkColor: props.linkColor,
			loginBackground: props.loginBackground,
			loginBackgroundImage: loginBackgroundImage,
			loginVisual: loginVisual,
			logo: logo,
			mainMenuBackground: props.mainMenuBackground,
			mainMenuColor: props.mainMenuColor,
			mainMenuListItemPaddingBottom: props.mainMenuListItemPaddingBottom,
			mainMenuListItemPaddingTop: props.mainMenuListItemPaddingTop,
			mainMenuSecondaryColor: props.mainMenuSecondaryColor,
			navigationColor: props.navigationColor,
			pdfIcon: pdfIcon,
			primaryBoxColor: props.primaryBoxColor,
			primaryColor: props.primaryColor,
			primaryElementBGColor: props.primaryElementBGColor,
			primaryPaletteColor: props.primaryPaletteColor,
			primaryTypeColor: props.primaryTypeColor,
			primaryWorkformColor: props.primaryWorkformColor,
			secondaryBackgroundColor: props.secondaryBackgroundColor,
			secondaryBoxColor: props.secondaryBoxColor,
			secondaryColor: props.secondaryColor,
			secondaryElementBGColor: props.secondaryElementBGColor,
			secondaryNavigationColor: props.secondaryNavigationColor,
			secondaryTitleFont: props.secondaryTitleFont,
			secondaryTypeColor: props.secondaryTypeColor,
			secondaryWorkformColor: props.secondaryWorkformColor,
			smallWrapper: props.smallWrapper,
			smartphoneBreakpoint: props.smartphoneBreakpoint,
			tabletLandscapeBreakpoint: props.tabletLandscapeBreakpoint,
			tabletPortraitBreakpoint: props.tabletPortraitBreakpoint,
			tertiaryColor: props.tertiaryColor,
			typeColor: props.typeColor,
            subHeaderColor: props.subHeaderColor,
            softBorderColor: props.softBorderColor,
			underline: {
				width: '50px',
				height: '2px',
				backgroundColor: props.defaultContentColor[500],
				position: 'absolute',
				bottom: 0,
				left: '50%',
				transform: 'translate(-50%, 2.5rem)',
			},
			visual: {
				backgroundImage: `url(${loginVisual})`,
				width: '100%',
				height: '100%',
				backgroundSize: 'cover',
				display: 'none',
			},
			workformTitleFont: props.workformTitleFont,
		}),
		overrides: {
            AccordionFaq: {
                panelHeading: {
                    fontSize: '1.8rem',
					color: black[500]
                },
				content: {
					color: black[300]
				},
                panelRoot: {
                    padding: 0,
                    minHeight: 0,
                    backgroundColor: props.defaultBorderColor[500],
                    marginBottom: '1px',
                },
                expansionPanelSummary: {
                    padding: '0 2.556rem',
                },
                expansionPanelDetails: {
                    margin: 0,
                    padding: '0 2.556rem 2.556rem',
                },
            },
            AppAccount: {
                changePasswordIcon: {
                    maxHeight: 'none',
                },
            },
			MuiStepConnector: {
				root: {
					display: 'none',
				},
			},
			MuiStepIcon: {
				root: {
					transform: 'scale(1.7)',
					margin: '0 10px',
				},
				text: {
					fontFamily: "'Zilla Slab', serif",
					fontSize: '1.2rem',
				},
			},
			MuiStepLabel: {
				iconContainer: {
					padding: '0 20px 0 0',
				},
				labelContainer: {
					display: 'none',
				},
			},
			MuiStepper: {
				root: {
					backgroundColor: 'transparent',
					padding: '0',
					margin: '30px 0',
				},
			},
            AppAccountWrapper: {
                root: {
                    background: 'transparent',
                },
                title: {
                    letterSpacing: 0,
                },
            },
            AppBar: {
                programsDropdownRoot: {
                    marginTop: 0,
                },
                logo: {
                    width: '356px',
                    height: '100px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '0',
                    [props.breakpoints.down('sm')]: {
                        maxWidth: '28vw',
                        height: '44px',
                        padding: 0,
                    },
                    '&:before': {
                        content: '""',
                        display: 'inline-block',
                        background: `url(${logo}) top center / contain no-repeat`,
                        maxWidth: '159px',
                        width: '100%',
                        height: '56px',
                        [props.breakpoints.down('sm')]: {
                            backgroundPosition: 'center',
                            width: '100%',
                            height: '100%',
                        },
                    },
                },
                buttonRoot: {
                    marginLeft: '1rem',
                },
                menuButton: {
                    [props.breakpoints.down('sm')]: {
                        marginRight: '1rem',
                    },
                },
                menuButtonLabel: {
                    fontFamily: props.defaultTitleFont,
                    verticalAlign: 'middle',
                    fontSize: '2rem',
                    paddingLeft: '.85rem',
                    display: 'none',
                    [props.breakpoints.up('md')]: {
                        display: 'inline-block',
                    },
                },
                name: {
                    fontFamily: props.defaultTitleFont,
                    fontWeight: 600,
                    fontSize: '2rem',
                },
                menuText: {
                    fontFamily: props.defaultTitleFont,
                    fontSize: '2rem',
                    fontWeight: 600,
                    marginLeft: 0,
                    paddingLeft: '1.5rem',
                    [props.breakpoints.up('md')]: {
                        fontSize: '2rem',
                        marginLeft: 0,
                    },
                },
                backButtonRoot: {
                    padding: '8px',
                    color: props.defaultContentColor[500],
                },
            },
			AppBoxCard: {
				backgroundImage: {
					transform: 'scale(1.1)',
				},
				inner: {
					borderRadius: '12px',
					[props.breakpoints.up('md')]: {
						height: '40rem',
						padding: '0 6rem',
					},
					'&:hover': {
						'& $backgroundImage': {
							transform: 'translateX(2%) scale(1.1)',
						},
						'& $overflow': {
							transform: 'scale(1) translate(2%, -20px)',
						},
						'& $boxDescription': {
							visibility: 'visible',
							transform: 'none',
							opacity: 1,
						},
						'& $boxSubtitle': {
							display: 'none',
						},
						'& $boxName': {
							opacity: 0,
							visibility: 'hidden',
							transform: 'translateY(-32px)',
						},
						'& $boxCardLabel': {
							transform: 'none',
						},
					},
				},
				innerDone: {
					[props.breakpoints.up('md')]: {
						height: '30rem',
					},
				},

				boxDescription: {
					position: 'absolute',
					marginTop: 0,
					visiblity: 'hidden',
					opacity: 0,
					transform: 'translateY(32px)',
					transition: 'all .2s ease',
					color: yellow[500],
				},
				boxCardText: {
					color: black[500],
					[props.breakpoints.up('md')]: {
						fontSize: '1.4rem',
					},
				},
				listView: {
					'& $boxName': {
						letterSpacing: 0,
						[props.breakpoints.up('md')]: {
							letterSpacing: 0,
						},
					},
					'&:hover': {
						'& $boxName': {
							opacity: 1,
							transform: 'translateY(0)',
							visibility: 'visible',
						},
					},
				},
				percentage: {
					fontSize: '1.75rem',
					letterSpacing: 0,
					width: '4rem',
					height: '4rem',
					[props.breakpoints.up('md')]: {
						fontSize: '1.75rem',
						width: '4rem',
						height: '4rem',
					},
				},
				percentageDone: {
					backgroundColor: black[500],
					color: white[500],
				},
			},
            AppBoxes: {
                smallWrapper: {
                    backgroundColor: 'transparent',
                },
            },
            AppBoxHeader: {
                root: {
                    padding: '12rem 0 0',
                    [props.breakpoints.up('md')]: {
                        padding: '12rem 0 1.2rem',
                    },
                },
                heading: {
                    paddingBottom: '3.6rem',
                },
                body: {
                    fontFamily: props.defaultTitleFont,
                    fontSize: '2.2rem',
                },
            },
            AppBoxLayout: {
                listView: {
                    backgroundColor: 'transparent',
                },
            },
            AppCaseContent: {
                title: {
                    fontSize: '2.2rem',
                },
                sidebar: {
                    [props.breakpoints.down('sm')]: {
                        width: '100%',
                    },
                },
            },
            AppCaseHeader: {
                subheading: {
                    color: white[500],
                    [props.breakpoints.up('md')]: {
                        marginBottom: '2rem',
                    },
                },
                body: {
                    color: white[500],
                },
                button: {
                    backgroundColor: props.secondaryPaletteColor[500],
                    padding: '13px 2.6rem',
                },
            },
            AppCheckQuestion: {
                formControl: {
                    width: '100%',
                },
                formControlRoot: {
                    minHeight: '10rem',
                    [props.breakpoints.up('md')]: {
                        borderColor: props.defaultBorderColor[500],
                        borderRightColor: props.defaultBorderColor[500],
                    },
                },
                formControlLabel: {
                    fontSize: '1.8rem',
                    lineHeight: 2,
                },
                button: {
                    background: props.tertiaryPaletteColor[500],
                    padding: '2.45rem 2rem',
                    borderRadius: '64px',
                },
            },
            AppChoiceQuestion: {
                header: {
                    fontSize: '2.778rem',
                    marginTop: '1.8rem',
                    textAlign: 'center',
                    wordBreak: 'break-word',
                    fontWeight: 'bold',
                    marginBottom: '3.7rem',
                },
            },
            AppDashboardHeader: {
                root: {
                    padding: '12rem 0 50rem',
                    backgroundColor: white[500],
                    [props.breakpoints.up('md')]: {
                        padding: '16rem 0 50rem'
                    },
                    '& $contentColor': {
                        color: props.defaultTitleColor[500],
                    },
                },
                body: {
                    fontSize: '2.2rem',
                    lineHeight: '1.8',
                },
                heading: {
                    fontSize: '4rem',
                    [props.breakpoints.up('md')]: {
                        fontSize: '8rem',
                    },
                },
            },
            AppDownloadButton: {
                title: {
                    fontSize: '3rem',
                    lineHeight: '1.5',
                },
                content: {
                    [props.breakpoints.up('md')]: {
                        padding: '3rem',
                    },
                },
                body: {
                    fontSize: '1.8rem',
                },
            },
            AppElementCard: {
                root: {
                    borderRadius: '10px',
                },
                name: {
                    letterSpacing: 0,
                    lineHeight: '1.25',
                    color: white[500],
                    [props.breakpoints.up('md')]: {
                        letterSpacing: 0,
                        lineHeight: '1.25',
                        fontSize: '3.2rem',
                    },
                },
                continue: {
                    '& $doneIcon': {
                        color: white[500],
                    },
                },
                done: {
                    backgroundColor: 'white',
                    width: '3.556rem',
                    height: '3.556rem',
                },
                doneIcon: {
                    color: props.primaryColor[500],
                    width: '2rem',
                    height: '2rem',
                },
                type: {
                    marginBottom: '1rem',
                    lineHeight: 1,
                    fontSize: '1.8rem',
                    fontFamily: props.defaultContentFont,
                    color: white[500],
                },
				button: {
					minHeight: '3rem',
					padding: '1.8rem!important',
					[props.breakpoints.up('md')]: {
						minWidth: '27rem',
					},
					'& span': {
						fontSize: '1.8rem',
					},
				},
			},
            AppElementOverlay: {
                title: {
                    letterSpacing: 0,
                    fontSize: '2rem',
                },
                closeButton: {
                    background: props.primaryColor[500],
                },
            },
            AppExpansionPanel: {
                detailRoot: {
                    fontSize: '1.6rem',
                },
            },
            AppFactcardReferral: {
                title: {
                    color: 'white',
                },
                body: {
                    color: 'white',
                },
            },
            AppFactcardsCard: {
                bodytitle: {
                    color: 'white',
                },
                body: {
                    color: 'white',
                },
            },
            AppFactCardTitles: {
                active: {
                    '& $title': {
                        color: props.primaryColor[500],
                    },
                    '& $longArrow': {
                        '& .arrow': {
                            background: props.primaryColor[500],
                        },
                    },
                },
            },
            AppFeedbackBox: {
                button: {
                    padding: '2.45rem 2rem',
                    background: props.tertiaryPaletteColor[500],
                    borderRadius: '64px',
                },
            },
            AppHotspot: {
                formControl: {
                    width: '100%',
                },
                formControlRoot: {
                    minHeight: '5.556rem',
                    [props.breakpoints.up('md')]: {
                        borderColor: props.defaultBorderColor[500],
                        borderRightColor: props.defaultBorderColor[500],
                    },
                },
                formControlLabel: {
                    fontSize: '1rem',
                    lineHeight: 2,
                },
                button: {
                    background: props.tertiaryPaletteColor[500],
                    padding: '2.45rem 2rem',
                    borderRadius: '64px',
                },
            },
            AppHotspotMapLandscape: {
                question: {
                    fontSize: '5rem',
                    marginTop: 0,
                },
                header: {
                    [props.breakpoints.down('md')]: {
                        fontSize: '3rem',
                        lineHeight: '1.2',
                    },
                },
                subHeader: {
                    '&::after': {
                        display: 'inline-block',
                        left: '50%',
                        transform: 'translateX(-50%)',
                    },
                },
                button: {
                    right: '1.8rem',
                    bottom: '1.8rem',
                },
            },
            AppImageAndTextCheckQuestion: {
                formControl: {
                    width: '100%',
                },
                formControlRoot: {
                    minHeight: '10rem',
                    [props.breakpoints.up('md')]: {
                        borderColor: props.defaultBorderColor[500],
                        borderRightColor: props.defaultBorderColor[500],
                    },
                },
                formControlLabel: {
                    fontSize: '1.8rem',
                    lineHeight: 2,
                },
                button: {
                    background: props.tertiaryPaletteColor[500],
                    padding: '2.45rem 2rem',
                    borderRadius: '64px',
                },
            },
            AppImageAndTextMPC: {
                formControl: {
                    width: '100%',
                },
                formControlRoot: {
                    minHeight: '10rem',
                    [props.breakpoints.up('md')]: {
                        borderColor: props.defaultBorderColor[500],
                        borderRightColor: props.defaultBorderColor[500],
                    },
                },
                formControlLabel: {
                    fontSize: '1.8rem',
                    lineHeight: 2,
                },
                button: {
                    background: props.tertiaryPaletteColor[500],
                    padding: '2.45rem 2rem',
                    borderRadius: '64px',
                },
            },
            AppImageCheckQuestion: {
                formControl: {
                    width: '100%',
                },
                formControlRoot: {
                    minHeight: '5.556rem',
                    [props.breakpoints.up('md')]: {
                        borderColor: props.defaultBorderColor[500],
                        borderRightColor: props.defaultBorderColor[500],
                    },
                },
                formControlLabel: {
                    fontSize: '1rem',
                    lineHeight: 2,
                },
                button: {
                    background: props.tertiaryPaletteColor[500],
                    padding: '2.45rem 2rem',
                    borderRadius: '64px',
                },
            },
            AppImageMPC: {
                formControl: {
                    width: '100%',
                },
                formControlRoot: {
                    minHeight: '10rem',
                    [props.breakpoints.up('md')]: {
                        borderColor: props.defaultBorderColor[500],
                        borderRightColor: props.defaultBorderColor[500],
                    },
                },
                formControlLabel: {
                    fontSize: '1.8rem',
                    lineHeight: 2,
                },
                button: {
                    background: props.tertiaryPaletteColor[500],
                    padding: '2.45rem 2rem',
                    borderRadius: '64px',
                },
            },
            AppImageWithSubtitle: {
                content: {
                    marginTop: '1rem',
                    fontWeight: 'normal',
                    fontSize: '1.8rem',
                },
            },
            AppLayoutButtons: {
                color: props.primaryPaletteColor[500],
                label: {
                    fontFamily: props.defaultContentFont,
                    fontSize: '1.8rem',
                },
                tabRoot: {
                    width: '6.4rem',
                    height: '6.4rem',
                    minWidth: 0,
                    minHeight: 0,
                    borderWidth: '2px',
                    borderColor: props.secondaryBorderColor[500],
                    backgroundColor: props.secondaryBorderColor[500],
                    opacity: 1,
                    color: 'currentColor',
                    '& svg': {
                        width: 24,
                        height: 24,
                        opacity: '.25',
                    },
                },
                tabSelected: {
                    backgroundColor: 'white',
                    border: `2px solid ${props.secondaryBorderColor[500]}`,
                    '& svg': {
                        color: props.primaryPaletteColor[500],
                        opacity: 1,
                    },
                },
            },
            AppLesson: {
				largeWrapper: {
					maxWidth: '1230px',
					[props.breakpoints.up('md')]: {
						maxWidth: '1230px',
					},
				},
                normalMargin: {
					margin: '3rem 0',
                    [props.breakpoints.up('md')]: {
						margin: '8rem 0',
                    },
                },
            },
            AppLessonBottom: {
                button: {
                    '&:hover': {
                        backgroundColor: 'transparent',
                    },
                    [props.breakpoints.up('md')]: {
                        fontSize: '7rem',
                        height: '45rem',
                    },
                },
                backToBoxButton: {
                    width: '100%',
                },
                redoQuestionButton: {
                    borderTop: '1px solid black',
                    display: 'flex',
                    paddingRight: '6rem',
                    [props.breakpoints.up('md')]: {
                        borderTop: '0',
                        borderLeft: '1px solid black',
                        paddingRight: '16px',
                    },
                },
            },
            AppLessonHeader: {
                heading: {
                    fontWeight: 600,
                    letterSpacing: 0,
                    [props.breakpoints.down('sm')]: {
                        letterSpacing: 0,
                        color: white[500],
                        fontSize: '5rem',
                    },
                    [props.breakpoints.up('md')]: {
                        fontSize: '10rem',
                    },
                    [props.breakpoints.down('md')]: {
                        lineHeight: '1.25',
                    },
                },
                fontStyle: {
                    fontWeight: 'normal',
                    lineHeight: 1,
                    fontSize: '2.2rem',
                    marginBottom: '6rem',
                },
                timing: {
                    fontFamily: props.defaultAnswerFont,
                    fontSize: '1.8rem',
                },
                clockIcon: {
                    marginRight: '1rem',
                    width: '2rem',
                    height: '2rem',
                },
            },
            AppLessonIntroduction: {
                wrapper: {
                    paddingTop: '3rem',
                },
                content: {
                    fontSize: '1.8rem',
                    lineHeight: '1.5',
                    [props.breakpoints.up('md')]: {
                        fontSize: '3rem',
                    },
                    '&:first-letter': {
                        [props.breakpoints.up('md')]: {
                            fontSize: '18rem',
                            lineHeight: 1,
                        },
                    },
                },
            },
            AppLoader: {
                progress: {
                    color: props.primaryColor[500],
                },
            },
            AppLogin: {
                root: {
                    padding: '34px',
                },
                link: {
                    color: 'rgba(11,19,24,0.5)',
                    marginTop: '1.8rem',
                },
            },
            appLoginHeader: {
                root: {
                    backgroundColor: 'white',
                },
                headerTitle: {
                    color: black,
                    marginBottom: '4.25rem'
                },
				logo: {
					height: 64,
					width: 240,
					backgroundSize: 'contain',
					backgroundRepeat: 'no-repeat',
					right: 'unset',
					position: 'relative',
					marginBottom: 32
				},
            },
            AppMenu: {
                paperRoot: {
                    padding: '0 2.4rem',
                    background: props.primaryColor[500],
                    [props.breakpoints.down('sm')]: {
                        justifyContent: 'flex-start',
                    },
                },
                link: {
                    fontFamily: props.defaultTitleFont,
                    fontSize: '3.2rem',
                    [props.breakpoints.up('md')]: {
                        fontSize: '4.75rem',
                    },
                },
                privacyRoot: {
                    left: '2.4rem',
                },
                listItemRoot: {
                    [props.breakpoints.down('sm')]: {
                        paddingTop: '8px',
                        paddingBottom: '8px',
                    },
                },
            },
            AppMoodboard: {
                title: {
                    fontFamily: props.defaultTitleFont,
                },
                text: {
                    fontSize: '1.8rem',
                    lineHeight: 1.77,
                    fontFamily: props.defaultContentFont,
                    color: props.primaryPaletteColor[500],
                    [props.breakpoints.up('md')]: {
                        fontSize: '1.8rem',
                        lineHeight: 1.77,
                    },
                },
            },
            AppOrderQuestion: {
                formControl: {
                    width: '100%',
                },
                formControlRoot: {
                    minHeight: '5.556rem',
                    [props.breakpoints.up('md')]: {
                        borderColor: props.defaultBorderColor[500],
                        borderRightColor: props.defaultBorderColor[500],
                    },
                },
                formControlLabel: {
                    fontSize: '1.8rem',
                    lineHeight: 2,
                },
                text: {
                    fontSize: '1.8rem',
                },
                number: {
                    [props.breakpoints.up('md')]: {
                        fontSize: '1.8rem',
                    },
                },
                button: {
                    background: props.tertiaryPaletteColor[500],
                    padding: '2.45rem 2rem',
                    borderRadius: '64px',
                },
                card: {
                    border: `1px solid ${props.defaultBorderColor[500]}`,
                    backgroundColor: 'white',
                    color: props.defaultContentColor[500],
                },
            },
            AppRadioGroup: {
                formControl: {
                    width: '100%',
                },
                formControlRoot: {
                    minHeight: '10rem',
                    [props.breakpoints.up('md')]: {
                        borderColor: props.defaultBorderColor[500],
                        borderRightColor: props.defaultBorderColor[500],
                    },
                },
                formControlLabel: {
                    fontSize: '1.8rem',
                    lineHeight: 2,
                    [props.breakpoints.up('md')]: {
                        fontSize: '1.8rem',
                        lineHeight: 2,
                    },
                },
            },
			AppRegistrationAuthData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationPersonalData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationSchoolClassData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationSchoolData: {
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
			},
			AppRegistrationScreen: {
				root: {
					minHeight: '100vh',
					backgroundColor: white[500],
				},
				heading: {
					textAlign: 'center',
				},
			},
			AppRegistrationSuccess: {
				content: {
					margin: '0 0 0px',
				},
				heading: {
					...props.registrationHeading.large,
					[props.breakpoints.down('md')]: {
						...props.registrationHeading.small,
					},
				},
				buttonRoot: {
					backgroundColor: props.homeButtonBGColor[500],
					color: props.homeButtonBGColor['contrastText']
				},
				root: {
					backgroundColor: props.primaryColor[500],
				},
				paragraph: {
					color: white[500],
				},
				smallParagraph: {
					color: white[500],
				},
			},
            AppResumeButton: {
				root: {
					border: `2px solid ${props.secondaryBorderColor[500]}`,
					borderRadius: '10px',
					overflow: 'hidden',
					'&:hover': {
						'& $body1': {
							color: props.createdPalette[500],
						},
						'& $longArrow': {
							'& .arrow': {
								backgroundColor: props.primaryColor[500],
							},
						},
					},
					'& $title': {
						fontWeight: 'normal',
					},
				},
            },
            AppSearchBar: {
                closeButton: {
                    background: props.primaryColor[500],
                },
            },
            AppSortSlider: {
                controls: {
                    backgroundColor: 'transparent',
                    [props.breakpoints.up('md')]: {
                        padding: '5rem 8rem',
                    },
                },
                progress: {
                    fontSize: '1.8rem',
                    backgroundColor: props.primaryColor[500],
                    color: white[500],
                    width: '6rem',
                    height: '6rem',
                },
                button: {
                    padding: '2.45rem 2rem',
                    borderRadius: '64px',
                    [props.breakpoints.up('md')]: {
                        marginRight: '1rem',
                        padding: '2.45rem 2rem',
                        borderRadius: '64px',
                    },
                    '&:nth-child(even)': {
                        [props.breakpoints.up('md')]: {
                            marginLeft: '1rem',
                        },
                    },
                },
            },
            AppStatementQuestion: {
                button: {
                    padding: '2.45rem 2rem',
                    // background: props.secondaryPaletteColor[500],
                    borderRadius: '64px',
                    '&:hover': {
						background: props.primaryPaletteColor[500]
                    },
                },
                primaryColors: {
                    /*'& $button': {
                        backgroundColor: props.secondaryPaletteColor[500],
                        '&:hover': {
                            background: props.primaryPaletteColor[500],
                        },
                    },*/
                },
            },
            AppTextMPC: {
                formControl: {
                    width: '100%',
                },
                formControlRoot: {
                    minHeight: '10rem',
                    [props.breakpoints.up('md')]: {
                        borderColor: props.defaultBorderColor[500],
                        borderRightColor: props.defaultBorderColor[500],
                    },
                },
                formControlLabel: {
                    fontSize: '1.8rem',
                    lineHeight: 2,
                },
                button: {
                    background: props.tertiaryPaletteColor[500],
                    padding: '2.45rem 2rem',
                    borderRadius: '64px',
                },
            },
            AppTitleAndText: {
                root: {
                    padding: '3rem 0',
                    [props.breakpoints.up('md')]: {
                        padding: '8rem 0',
                    },
                },
                header: {
                    fontFamily: props.defaultTitleFont,
                    fontSize: '3rem',
                    marginBottom: '3.2rem',
                    paddingBottom: '3.2rem',
                    color: white[500],
                    lineHeight: '1.2',
                    '&.smallUnderline': {
                        '&:after': {
                            backgroundColor: white[500],
                        },
                    },
                    [props.breakpoints.down('sm')]: {
                        fontSize: '3rem',
                        lineHeight: '1.2',
                    },
                    [props.breakpoints.up('md')]: {
                        fontSize: '3.2rem',
                    },
                },
                content: {
                    fontSmoothing: 'antialiased',
                    color: white[500],
                    fontSize: '1.8rem',
                    lineHeight: '1.77',
                    '& ul, & ol': {
                        listStylePosition: 'outside',
                        paddingLeft: '1rem',
                    },
                    '& p:last-child': {
                        margin: 0,
                    },
                },
                secondary: {
                    '& $header': {
                        color: white[500],
                        '&.smallUnderline': {
                            '&:after': {
                                backgroundColor: white[500],
                            },
                        },
                    },
                    '& $content': {
                        color: white[500],
                    },
                },
            },
            AppTitleAndTextImage: {
                header: {
                    fontSize: '3.2rem',
                    fontFamily: props.defaultTitleFont,
                    marginBottom: '3.2rem',
                    paddingBottom: '3.2rem',
                    '&:after': {
                        backgroundColor: white[500],
                    },
                },
                image: {
                    objectFit: 'cover',
                },
                secondary: {
                    '& $header': {
                        '&:after': {
                            backgroundColor: white[500],
                        },
                    },
                    '& $contentColor': {
                        color: white[500],
                    },
                },
            },
            AppWhatIsWhatItem: {
				correctIcon: {
					backgroundColor: props.correctAnswer[500],
				},
            },
            AppWhatIsWhatQuestion: {
                button: {
                    padding: '2.45rem 2rem',
                    background: props.tertiaryPaletteColor[500],
                    borderRadius: '64px',
                },
            },
            AppWorkformLayout: {
                header: {
                    fontSize: '5rem',
                    lineHeight: '1.2',
                    hyphens: 'none',
                    [props.breakpoints.down('sm')]: {
                        lineHeight: '1.2',
                        fontSize: '3rem',
                    },
                },
                subHeader: {
                    fontSize: '1.8rem',
                    lineHeight: 2,
                    [props.breakpoints.up('md')]: {
                        fontSize: '2.2rem',
                    },
                },
            },
			AuthenticationCmsAuthenticator: {
				root: {
					backgroundColor: white[500],
				},
			},
            AuthenticationConfirmReset: {
                root: {
                    backgroundColor: 'white',
                },
            },
            AuthenticationLoginForm: {
                inputRoot: {
                    marginBottom: '16px',
                },
                label: {
                    fontFamily: props.defaultContentFont,
                    fontSize: '1.8rem',
					color: black
                },
                input: {
					color: black,
                    '&::before': {
                        borderBottom: `2px solid ${props.defaultBorderColor[500]}`,
                    },
                },
                nativeInput: {
                    height: '3rem',
                    fontSize: '1.8rem',
                },
                submit: {
                    marginTop: '36px',
                },
            },
            AuthenticationResetPassword: {
                root: {
                    backgroundColor: 'white',
                },
            },
            HeaderFaq: {
                root: {
                    backgroundColor: props.tertiaryPaletteColor[500],
                },
                heading: {
                    letterSpacing: 0,
                    fontSize: '5.556rem',
                    textTransform: 'none',
                },
            },
            HelpButton: {
                question: {
                    fontSize: '2.4rem',
                    fontFamily: props.defaultTitleFont,
                    fontWeight: 600,
                    lineHeight: 1,
                },
                icon: {
                    marginTop: 0,
                },
                showIcon: {
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
                paper: {
                    bottom: '5rem',
                },
                summary: {
                    fontSize: '1.8rem',
                },
                heading: {
                    fontSize: '2.6rem',
                },
            },
            MuiButton: {
                root: {
                    fontFamily: props.defaultTitleFont,
                    fontSize: '1.8rem',
                    textTransform: 'none',
                    borderRadius: '30px',
                    padding: '13px 16px',
                },
                containedPrimary: {
                    fontFamily: props.defaultTitleFont,
                    backgroundColor: props.primaryColor[500],
                    fontWeight: '600',
                    boxShadow: 'none',
                },
                outlinedPrimary: {
                    border: `2px solid ${props.primaryColor[500]}`,
                    '&:hover': {
                        border: `2px solid ${props.primaryColor[500]}`,
                    },
                },
                outlined: {
                    fontFamily: props.defaultTitleFont,
                    padding: '13px 16px',
                    fontWeight: '600',
                },
            },
            MuiFormHelperText: {
                root: {
                    fontSize: '1.3rem',
                },
            },
            MuiFormLabel: {
                root: {
                    color: props.tertiaryColor,
                    fontSize: '1.8rem',
                },
            },
            MuiInputBase: {
                input: {
                    fontSize: '1.8rem',
                },
            },
            MuiMenuItem: {
                root: {
                    fontSize: '1.8rem',
                },
            },
            MuiSlider: {
                root: {
                    marginTop: '26px',
                },
                rail: {
                    height: '4px',
                    backgroundColor: props.defaultContentColor[500],
                },
                track: {
                    height: '4px',
                },
                thumb: {
                    width: '30px',
                    height: '30px',
                    marginTop: '-13px',
                    marginLeft: '-13px',
                },
                valueLabel: {
                    backgroundColor: 'transparent',
                    color: props.primaryPaletteColor[500],
                    left: 'calc(-50% + 13px)',
                    fontSize: '2.2rem',
                    fontWeight: 'bold',
                    '& *': {
                        backgroundColor: 'transparent',
                        color: 'inherit',
                    },
                },
            },
            MuiSvgIcon: {
                root: {
                    width: '2rem',
                    height: '2rem',
                },
            },
            MuiTypography: {
                root: {
                    fontSize: '2rem',
                },
                h1: {
                    fontFamily: props.defaultTitleFont,
                    fontSize: '4rem',
                    letterSpacing: 0,
                    lineHeight: '1.25',
                    color: props.defaultTitleColor[500],
                    marginBottom: '1.4rem',
                    [props.breakpoints.up('md')]: {
                        fontSize: '5rem',
                        lineHeight: '1.25',
                    },
                },
                h2: {
                    fontFamily: props.defaultTitleFont,
                    fontSize: '4rem',
                    lineHeight: '1.2',
                    color: props.defaultTitleColor[500],
                    fontWeight: 600,
                    marginBottom: '1.4rem',
                    letterSpacing: 0,
                    [props.breakpoints.up('md')]: {
                        fontSize: '8rem',
                        //lineHeight: '13.5rem'
                    },
                },
                h3: {
                    fontFamily: props.defaultTitleFont,
                    fontSize: '3.8rem',
                    lineHeight: '150%',
                    color: props.defaultTitleColor[500],
                    fontWeight: 800,
                    marginBottom: '1.4rem',
                    letterSpacing: 0,
                    [props.breakpoints.up('md')]: {
                        fontSize: '3.8rem',
                    },
                },
                h4: {
                    color: props.defaultTitleColor[500],
                    fontFamily: props.defaultTitleFont,
                    fontWeight: 600,
                    fontSize: '2.8rem',
                    lineHeight: 2,
                    marginBottom: '3.6rem',
                    letterSpacing: 0,
                    [props.breakpoints.up('md')]: {
                        fontSize: '2.8rem',
                        lineHeight: 2,
                        marginBottom: '4.6rem',
                    },
                },
                h5: {
                    color: props.defaultTitleColor[500],
                    fontFamily: props.secondaryTitleFont,
                    letterSpacing: 0,
                    fontSize: '2.2rem',
                    fontWeight: 'bold',
                    lineHeight: '1.2',
                    marginBottom: '1.3rem',
                    [props.breakpoints.up('md')]: {
                        lineHeight: '1.2',
                        marginBottom: '0rem',
                    },
                },
                h6: {
                    color: props.defaultTitleColor[500],
                    fontFamily: props.defaultTitleFont,
                    fontSize: '1.8rem',
                    fontWeight: 'bold',
                    lineHeight: '1.2',
                    marginBottom: '1.3rem',
                    letterSpacing: 0,
                    [props.breakpoints.up('md')]: {
                        marginBottom: '1.6rem',
                    },
                },
                subtitle1: {
                    fontFamily: props.secondaryTitleFont,
                    fontSize: '2.2rem',
                    lineHeight: '2',
                    color: props.defaultContentColor[500],
                    marginBottom: '1rem',
                    fontWeight: 'bold',
                },
                subtitle2: {
                    fontFamily: props.secondaryTitleFont,
                    fontSize: '1.8rem',
                    lineHeight: '1.2',
                    color: props.defaultContentColor[500],
                    marginBottom: '1rem',
                    fontWeight: '500',
                    [props.breakpoints.up('md')]: {
                        marginBottom: '4rem',
                    },
                },
                body1: {
                    fontSize: '1.8rem',
                    color: props.defaultContentColor[500],
                    lineHeight: '1.8',
                    marginBottom: '2rem',
                    [props.breakpoints.up('md')]: {
                        marginBottom: '3rem',
                    },
                },
                body2: {
                    fontSmoothing: 'auto',
                    color: props.defaultContentColor[500],
                    fontWeight: 600,
                    fontSize: '1.8rem',
                    lineHeight: '2',
                    fontFamily: props.secondaryTitleFont,
                    marginBottom: '2rem',
                    [props.breakpoints.up('md')]: {
                        fontWeight: 400,
                    },
                },
                gutterBottom: {
                    marginBottom: '3em',
                },
            },
            SingleDot: {
				HotspotBlockText: {
					lineHeight: '28px',
				},
            },
        },
	});

export default theme;
