import logo from "../assets/logo.svg";

export default async () =>
    new Promise(async (resolve) => {
        const config = {
            whiteLabel: 'Regio College',
            defaultLang: 'nl_NL',
            files: [
                {
                    id: 'testfile',
                    file: logo,
                },
            ],
            pages: {
                appLogin: {
                    showHeaderTitle: false,
                    showLogo: true,
                    headerTitleIsHtml: false,
                    showFootNote: false,
                    visualTitle: true,
                    footerLogo: true,
                },
                appRegistration: {
                    showBirthDate: true,
                    showPhoneNumber: true,
                    showRegisterType: false,
                    showRegisterCode: false,
                    registerCodeRequired: false,
                    addMoreLicenses: true,
                },
                appNoLicense: {
                    showFootNote: false,
                },
                appAccount: {
                    showEducation: true,
                },
                box: {
                    scrollTo: false,
                },
                boxCard: {
                    showDescription: true,
                },
                dashboard: {
                    scrollTo: true,
                },
                appElementOverlay: {
                    showHeaderTitle: true,
                },
                general: {
                    showStudentRapports: true,
                    rapportType: 'userprogress',
                },
            },
            elementCardConfig: {
                noPercentage: false,
                noTime: false,
            },
            caseConfig: {
                redoButtons: true,
            },
            assignmentConfig: {
                redoButtons: true,
            },
            general: {
                showFilters: true,
                showMenuLabel: true,
                hideTimer: false,
                downloadRapportage: true,
                showTags: true,
                playButtonAsIcon: false,
                secondStatisticsBox: true,
                licenseUrl: 'https://www.visavi.nl/Leermiddelen/',
                showScenesButton: true,
                hideTeacherMessage: true
            },
            elements: {
                exerciseCard: true,
            },
            getFile: (id) => config.files.find((file) => file.id === id),
            getPage: (key) => config[key],
        };

        resolve(config);
    });
